import React, { Component } from 'react';
import data from './data.json';
import * as math from 'mathjs'

const locations = data.locations
const locationOptions = locations.map(location => location.id)

class ModelInputs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            parameters: {
                location: props.location || '',
                baseTemperature: props.baseTemperature || '',
                temperatureUnit: props.temperatureUnit || '',
                calculationType: props.calculationType || '',
                startDate: props.startDate || '',
                endDate: props.endDate || '',
                gddThreshold: props.gddThreshold || '',
                thresholdDay: props.thresholdDay || '',
            }
        };
        this.handleThresholdDayChange = this.handleThresholdDayChange.bind(this);
        this.handleDownloadClicked = this.handleDownloadClicked.bind(this);
    }
    
    handleLocationChange(event) {
        var parameters = this.state.parameters;
        parameters.location = event.target.value;
        this.setState({parameters: parameters})
    }

    handleBaseTemperatureChange(event) {
        var parameters = this.state.parameters;
        parameters.baseTemperature = event.target.value;
        this.setState({parameters: parameters})
    }

    handleTemperatureUnitChange(event) {
        var parameters = this.state.parameters;
        parameters.temperatureUnit = event.target.value;
        this.setState({parameters: parameters})
    }

    handleCalculationTypeChange(event) {
        var parameters = this.state.parameters;
        parameters.calculationType = event.target.value;
        this.setState({parameters: parameters})
    }

    handleStartDateChange(event) {
        var parameters = this.state.parameters;
        parameters.startDate = event.target.value;
        this.setState({parameters: parameters})
    }

    handleEndDateChange(event) {
        var parameters = this.state.parameters;
        parameters.endDate = event.target.value;
        this.setState({parameters: parameters})
    }

    handleGddThresholdChange(event) {
        var parameters = this.state.parameters;
        parameters.gddThreshold = event.target.value;
        this.setState({parameters: parameters})
        }
    
    handleThresholdDayChange(event) {
        var parameters = this.state.parameters;
        parameters.thresholdDay = event.target.value;
        this.setState({parameters: parameters})
        }


    handleButtonClicked() {
        var latitude = 'not set'
        var longitude = 'not set'
        let noMatch = 1
        while ( noMatch === 1 ) {
            for (let i = 0; i < locations.length; i++) {
                if (this.state.parameters.location === locations[i].id ) {
                    latitude = locations[i].lat
                    longitude = locations[i].lon
                    noMatch = 0
                    }
                } 
            }
    
    const unit = this.state.parameters.temperatureUnit
    const calcType = this.state.parameters.calculationType
    const threshold = this.state.parameters.gddThreshold
        
        // api request
        var rootUrl = 'https://data.rcc-acis.org/GridData?'
        var elems = "maxt,mint"
        var params = 'params={"sdate":"' + this.state.parameters.startDate + '","edate":"' + this.state.parameters.endDate + '","loc":"' + longitude + ',' + latitude + '","grid":"21","elems":"' + elems + '"}'
        var url = rootUrl + params
        
        // get data
        if ( 
            this.state.parameters.temperatureUnit === 'F' ) {
                var baseTempF = this.state.parameters.baseTemperature
                var baseTempC = (this.state.parameters.baseTemperature - 32) * (5/9)
                // console.log('Base temp F: ',baseTempF)
                // console.log('Base temp C: ',baseTempC)
        } else if ( 
            this.state.parameters.temperatureUnit === 'C' ) {
                baseTempF = (this.state.parameters.baseTemperature * 9/5 ) + 32
                baseTempC = this.state.parameters.baseTemperature
                // console.log('Base temp F: ',baseTempF)
                // console.log('Base temp C: ',baseTempC)
        }

        async function FetchData() {
            let response = await fetch(url);

            // console.log('RESPONSE: ',response.status);

            if (response.status === 200 ) {
                var data = await response.json();                
            }

            
            function gddSimpleAveF(a) {
                if ( a - baseTempF < 0 ) {
                    return 0
                } else if ( a - baseTempF >= 0 ) {
                    return a - baseTempF
                }
            }

            function gddSimpleAveC(a) {
                if ( a - baseTempC < 0 ) {
                    return 0
                } else if ( a - baseTempC >= 0 ) {
                    return a - baseTempC
                }
            }

            function gddSineWaveF(x,b,c) {
                if ( x < baseTempF && b < baseTempF ) {
                    return 0
                } else if (x >= baseTempF && b >= baseTempF ) {
                    return c - baseTempF
                } else if (x >= baseTempF && b < baseTempF ) {
                    var w = (x - b) / 2
                    var a = math.asin( (baseTempF - c) / w )
                    var gdd = ((w * math.cos(a)) - ((baseTempF - c ) * ((3.14/2) - a))) / 3.14
                    return gdd
                }
            }


            function gddSineWaveC(x,b,c) {
                if ( x < baseTempC && b < baseTempC ) {
                    return 0
                } else if (x >= baseTempC && b >= baseTempC ) {
                    return c - baseTempC
                } else if (x >= baseTempC && b < baseTempC ) {
                    var w = (x - b) / 2
                    var a = math.asin( (baseTempC - c) / w )
                    var gdd = ((w * math.cos(a)) - ((baseTempC - c ) * ((3.14/2) - a))) / 3.14
                    return gdd
                    }
                }

            var AccumulatorGddFahrenheitSimple = 0
            function RunningSumGddFahrenheitSimple(a) {
                AccumulatorGddFahrenheitSimple = AccumulatorGddFahrenheitSimple + a
                return AccumulatorGddFahrenheitSimple
            }

            var AccumulatorGddFahrenheitSine = 0
            function RunningSumGddFahrenheitSine(a) {
                AccumulatorGddFahrenheitSine = AccumulatorGddFahrenheitSine + a
                return AccumulatorGddFahrenheitSine
            }

            var AccumulatorGddCelsiusSimple = 0
            function RunningSumGddCelsiusSimple(a) {
                AccumulatorGddCelsiusSimple = AccumulatorGddCelsiusSimple + a
                return AccumulatorGddCelsiusSimple
            }

            var AccumulatorGddCelsiusSine = 0
            function RunningSumGddCelsiusSine(a) {
                AccumulatorGddCelsiusSine = AccumulatorGddCelsiusSine + a
                return AccumulatorGddCelsiusSine
            }

            function DataPacket() {
                var result = data.data.map((o) => ({
                    ...o,
                    date: o[0],
                    tempMaxF: o[1],
                    tempMinF: o[2],
                    tempMeanF: ( o[1] + o[2] ) / 2,
                    gddFahrenheitSimple: gddSimpleAveF((( o[1] + o[2] ) / 2)),
                    gddFahrenheitSine: gddSineWaveF(o[1],o[2],(( o[1] + o[2] ) / 2)),
                    gddFahrenheitSimpleCumsum: RunningSumGddFahrenheitSimple(gddSimpleAveF((( o[1] + o[2] ) / 2))),
                    gddFahrenheitSineCumSum: RunningSumGddFahrenheitSine(gddSineWaveF(o[1],o[2],(( o[1] + o[2] ) / 2))),
                    tempMaxC: ( o[1] - 32 ) * ( 5/9 ),
                    tempMinC: ( o[2] - 32 ) * ( 5/9 ),
                    tempMeanC: ((((o[1]-32) * (5/9)) + ((o[2]-32) * (5/9))) / 2),
                    gddCelsiusSimple: gddSimpleAveC(((( o[1] - 32 ) * ( 5/9 )) + (( o[2] - 32 ) * ( 5/9 ))) / 2),
                    gddCelsiusSine: gddSineWaveC((( o[1] - 32 ) * ( 5/9 )),(( o[2] - 32 ) * ( 5/9 )),((((o[1]-32) * (5/9)) + ((o[2]-32) * (5/9))) / 2)), 
                    gddCelsiusSimpleCumSum: RunningSumGddCelsiusSimple(gddSimpleAveC(((( o[1] - 32 ) * ( 5/9 )) + (( o[2] - 32 ) * ( 5/9 ))) / 2)),
                    gddCelsiusSineCumSum: RunningSumGddCelsiusSine(gddSineWaveC((( o[1] - 32 ) * ( 5/9 )),(( o[2] - 32 ) * ( 5/9 )),((((o[1]-32) * (5/9)) + ((o[2]-32) * (5/9))) / 2)))
                }));
                return result
            }

            function Append(dataPacket) {
                var result = dataPacket;
                                
                var a;
                if ( unit ==='F' && calcType === 'Simple') {
                    a = result.find(a => a.gddFahrenheitSimpleCumsum > threshold )
                } else if ( unit ==='F' && calcType === 'BE') {
                    a = result.find(a => a.gddFahrenheitSineCumSum > threshold )
                } else if ( unit ==='C' && calcType === 'Simple') {
                    a = result.find(a => a.gddCelsiusSimpleCumSum > threshold )
                } else if ( unit ==='C' && calcType === 'BE' ) {
                    a = result.find(a => a.gddCelsiusSineCumSum > threshold )
                }
                // return thresholdDay
            }

            var date = Append(DataPacket());
            // console.log('THRESHOLD REACHED: ', thresholdDay);
            document.getElementById('datePlaceholder').textContent = date;

        }
        var date = FetchData()
        return date
    }

    convertToCSV(data) {
        const replacer = (key, value) => value === null ? '' : value;
        const header = Object.keys(data[0]);
        let csv = data.map(row => header.map(fieldName => JSON.stringify(row[fieldName], replacer)).join(','));
        csv.unshift(header.join(','));
        return csv.join('\r\n');
    }

    downloadCSV(data) {
        const csv = this.convertToCSV(data);
        const blob = new Blob([csv], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'data.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
    }

    handleDownloadClicked() {
        const data = this.resultData; 
        this.downloadCSV(data);
    }

    render () {
      return (
        <div className="grid grid-cols-2 gap-4">
            <div name="input">
                <br />
                    <h2>Location</h2>
                    <select
                        name="location"
                        value={this.state.parameters.location}
                        onChange={this.handleLocationChange.bind(this)}>
                            <option value ="">Choose...</option>
                            {
                                locationOptions.map(optionValue => (
                                    <option key={optionValue} value={optionValue}>{optionValue}</option>
                                ))
                            }
                    </select>
                    <h2>Base temperature</h2>
                    <input 
                        type="number"
                        step="0.1"
                        min="0"
                        max="100" 
                        name="baseTemperature"
                        value={this.state.parameters.baseTemperature}
                        onChange={this.handleBaseTemperatureChange.bind(this)}
                        style={{ width: "150px "}} />
                    <h2>Temperature units</h2>
                    <select
                        name="temperatureUnit"
                        value={this.state.parameters.temperatureUnit}
                        onChange={this.handleTemperatureUnitChange.bind(this)}
                        style={{ width: "200px "}} >
                            <option value="">Choose...</option>
                            <option value="F">Fahrenheit</option>
                            <option value="C">Celsius</option>
                    </select>
                    <h2>Calculation type</h2>
                    <select
                        name="calculationType"
                        value={this.state.parameters.calculationType}
                        onChange={this.handleCalculationTypeChange.bind(this)}
                        style={{ width: "275px" }} >
                            <option value="">Choose...</option>
                            <option value="Simple">Simple daily average</option>
                            <option value="BE">Baskerville Emin (sine wave)</option>
                    </select>
                    <h2>Start date</h2>
                    <input 
                        type="date" 
                        name="startDate"
                        value={this.state.parameters.startDate}
                        onChange={this.handleStartDateChange.bind(this)} />
                    <h2>End date</h2>
                    <input 
                        type="date" 
                        name="startDate"
                        value={this.state.parameters.endDate}
                        onChange={this.handleEndDateChange.bind(this)} />
                    <h2>GDD Threshold</h2>
                    <input 
                        type="number" 
                        name="gddThreshold"
                        value={this.state.parameters.gddThreshold}
                        onChange={this.handleGddThresholdChange.bind(this)}
                        style={{ width: "100px "}} />
                    <br /><br />
                    <button
                        onClick={this.handleButtonClicked.bind(this)}
                        className="border-black rounded text-black  hover:border-white focus:outline-black focus:border-white focus:shadow-outline-primary active:border-white transition ease-in-out duration-150">
                        <h2><b>Calculate</b></h2>
                    </button>
            </div>
            <div name="output">
                <br /><br />
                <b>{this.state.parameters.location}</b><br />
                Base {this.state.parameters.baseTemperature}&deg; 
                {this.state.parameters.temperatureUnit}&nbsp;
                {this.state.parameters.calculationType} GDD Model<br />
                {this.state.parameters.startDate} to {this.state.parameters.endDate}<br />
                {this.state.parameters.gddThreshold} GDD threshold<br /><br />
                <div>Threshold reached on <mark id="datePlaceholder">{this.state.date}</mark></div>
                {this.resultData && this.resultData.length > 0 ?
                <button onClick={this.handleDownloadClicked}>Download CSV</button> : null}
            </div>
        </div>
      );
    }
}

export default function SeedcornMaggotSandbox() {

    return (
        <div className="bd-example example-padding" style={{padding: "0rem"}}>
            <div className="p-5">
                <h1><b>Sandbox | Seedcorn maggot GDD modeling</b></h1>
            <ModelInputs></ModelInputs>
            </div>
        </div>
    )
}


